<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="getTitleDatos">
						<v-row dense>
							<v-col cols="12">
								<v-row dense v-if="!isCreatingElement">
									<!-- IDGRUPPRI -->
									<v-col cols="4" md="2" lg="1">
										<pui-number-field
											:id="`idgruppri-mgrupoprioridad`"
											v-model="model.idgruppri"
											:label="$t('mgrupoprioridad.idgruppri')"
											:disabled="formDisabled"
											required
											toplabel
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- NAME -->
									<v-col cols="12" md="6">
										<pui-text-field
											:id="`name-mgrupoprioridad`"
											v-model="model.name"
											:label="$t('mgrupoprioridad.name')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- FECBAJA -->
									<v-col cols="4" lg="3">
										<pui-date-field
											:id="`fecbaja-mgrupoprioridad`"
											v-model="model.fecbaja"
											:label="$t('mgrupoprioridad.fecbaja')"
											:disabled="formDisabled"
											toplabel
											time
										></pui-date-field>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-col>
			</v-row>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading
			v-else
		></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mgrupoprioridad-form',
	mixins: [PuiFormMethodsMixin],
	components: {
	},
	data() {
		return {
			modelName: 'mgrupoprioridad'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mgrupoprioridad');
		}
	},
	created() {
	}
}
</script>
